import "../styles/typography.css"
export default {
  breakpoints: ["40em", "52em", "64em"],
  fontSizes: [12, 14, 16, 18, 20, 24, 28, 32, 40, 48, 64],
  colors: {
    text: "#7B848E",
    background: "#fff",
    primary: "#4EC3E0",
    secondary: "#3D587F",
    highlight: "#fff",
    blue: "#07c",
    lightgray: "#f6f6ff",
  },
  space: [0, 4, 8, 10, 16, 32, 64, 128, 256],
  fonts: {
    FONT_LIGHT: "Quicksand Light",
    FONT_REGULAR: "Quicksand Regular",
    FONT_BOLD: "Quicksand Bold",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)",
  },
  variants: {},
  text: {},
  buttons: {
    primary: {
      color: "white",
      bg: "primary",
    },
  },
}
